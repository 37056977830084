@import "sass/variables";
@import "sass/helpers/helpers";

$icon-size: 3.5em;

.spinner-info-container {
  justify-content: center;
  display: flex;

  .spinner-check-container {
    display: flex;

    svg {
      width: $icon-size;
      height: $icon-size;
      fill: $action-success;
      stroke: $color-17;
    }
  }
}
