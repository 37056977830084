* {
  @include ds-focus-indicator;
}

// Because of the selector above, most clicks in the application will
// show a focus indicator on this item, it has tabindex="-1"
// We move focus programmatically to this div with a skip link
// It doesn't need a focus indicator
// sass-lint:disable-block no-important
[tabindex="-1"] {
  // sass-lint:disable-block no-important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    @include ds-focus-indicator(true);
  }
}

// Safari hack
// sass-lint:disable no-vendor-prefixes
@supports (background: -webkit-named-image(i)) {
  select {
    @include ds-focus-indicator(true);
  }
}
