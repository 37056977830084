.scan-results {
  margin: 0;

  table {
    background: $color-theme-2;
    border: 1px solid $color-theme-2;
    margin: 0 0 $margin;

    caption {
      color: $color-theme-5;
      font-size: $h3-font-size;
      font-weight: 400;
      padding: 0 0 $padding;
      text-align: left;
    }

    thead {
      tr {
        &:last-child {
          th {
            border-right: 0;
          }
        }

        th {
          background-color: $table-default-color;
          border-right: solid $table-border-width $table-border-color;
          font-size: 90%;

          &.column-header-error {
            background: lighten(#f00, 47%);
            color: darken(#f00, 25%);
          }

          &.column-header-warning {
            background: lighten(#ffa500, 47%);
            color: darken(#ffa500, 25%);
          }

          &.column-header-passed {
            background: lighten(#008000, 70%);
            color: darken(#008000, 10%);
          }

          &.column-header-action {
            width: 10rem;
          }
        }
      }
    }

    tbody {
      tr {
        &.row-error {
          background: lighten(#f00, 47%);
          color: darken(#f00, 25%);
        }

        td {
          border-bottom: $table-border-width solid $color-theme-13;

          &.column-header-error,
          &.column-header-warning,
          &.column-header-passed,
          &.column-header-action {
            text-align: center;
          }

          &[data-header="Url"],
          &[data-header="Reason"] {
            word-break: break-all;
          }
        }
      }
    }

    @media #{$small-only} {
      tbody {
        tr {
          td {
            border-bottom: $table-border-width solid $color-theme-13;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
