@import "./helpers/functions";

// App container ID
$app-container-id: "app-container";

// Typography
$base-font-size: 16px;
$font-size-body: 1rem;
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base: Roboto, "SF Pro Text", "Helvetica Neue", "Helvetica", "Segoe UI", system-ui, "Arial", sans-serif;
$font-family-monospace: "Roboto Mono", Menlo, monospace;
$h1-font-family: Rubik, Roboto;

$font-size-base: 1.5rem;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 0.9rem;
$font-size: 1rem;
$font-size-lg: 1.4rem;
$font-size-xl: 2rem;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

$h1-font-size: $font-size * 1.75;
$h2-font-size: $font-size * 1.5;
$h3-font-size: $font-size * 1.25;
$h4-font-size: $font-size * 1.125;
$h5-font-size: $font-size;
$h6-font-size: $font-size;

$mobile-h1-font-size: $font-size * 1.5;
$mobile-h2-font-size: $font-size * 1.3755;
$mobile-h3-font-size: $font-size * 1.25;
$mobile-h4-font-size: $font-size * 1.125;
$mobile-h5-font-size: $font-size;
$mobile-h6-font-size: $font-size;

$font-size-18px: 1.125rem;

$card-spacing: 2rem;
$card-padding: 1.75rem;

$default-line-height: 1.5rem;
$default-form-element-padding-top-bottom: 0.5rem;
$default-form-element-padding-left-right: 0.75rem;

// Colors
$color-theme-1: #e6eaee;
$color-theme-2: #fff;
$color-theme-3: #f8f9fa;
$color-theme-4: #0043ce;
$color-theme-5: #000;
$color-theme-6: #222c3c;

$color-theme-8: #298335;
$color-theme-9: #eee;

$color-theme-11: #0665d0;
$color-theme-12: #b7c0cd;
$color-theme-13: #f0f2f5;
$color-theme-14: #f5f6f7;
$color-theme-15: #103c60;

$color-theme-17: #0466d9;
$color-theme-18: #051d2b;
$color-theme-19: #80669f;
$color-theme-20: #051c2b;
$color-theme-21: #ffc107;
$color-theme-22: #ffc;
$color-theme-23: #fcc;
$color-theme-24: #d14;
$color-theme-25: #0a64f9;
$color-theme-26: #d3d3d3;
$color-theme-27: #f5f8fc;

$color-5: #888;
$color-6: #36657c;
$color-7: #0af;
$color-8: #222;
$color-9: #525e8a;
$color-13: #555;
$color-14: #e1e3e9;
$color-15: #345;
$color-17: #fcfcfc;
$color-18: $color-theme-4;
$color-19: #690;
$color-20: #d23d24;
$color-21: #ccc;
$color-22: #fdd;
$color-23: #ddd;
$color-26: #e1e1e1;
$color-27: #90ee90;
$color-28: #b10606;
$color-29: #0857c5;
$color-30: #ebf3ff;
$color-31: #20652a;
$color-32: #d1d1d1;
$color-33: #002f57;
$color-34: #20652a;
$color-35: #dde5f0;
$color-36: #d2d2d2;
$color-37: #0e1a27;
$color-38: #f2f2f2;
$color-39: #9d0000;
$color-40: #a0288a;
$color-41: #dadee2;
$color-42: #b85000;
$color-43: #20652a;
$color-44: #ff4444; // sass-lint:disable-line hex-length
$color-45: #c2cad3;
$color-46: #b8b8b8;
$color-47: #343a40;
$color-48: #fff3cd;
$color-49: #212529;
$color-50: #f3f6ff;
$color-51: #003168;
$color-52: #c8d1d9;
$color-53: #856404;
$color-54: #f4f4f4;
$color-55: #b88e37;
$color-56: #ff0000; // sass-lint:disable-line hex-length
$color-57: #e5e5e5;
$color-59: #202223;
$color-60: #6f6f6f;
$color-61: #e4e5e7;
$color-62: #919191;
$color-63: #8c9196;
$color-64: #767676;
$color-65: #0a6080;
$color-66: #ebf9fc;
$color-67: #0e7398;
$color-68: #e1b878;
$color-69: #c38923;
$color-70: #c38923;
$color-71: #006341;
$color-72: #439e2c;
$color-73: #094caa;
$color-74: #063863;
$color-75: #100604;
$color-76: #b0008e;
$color-77: #102e54;
$color-78: #ffeeba;
$color-79: #cce5ff;
$color-80: #b8daff;
$color-81: #757575;
$color-82: #adb5bd;
$color-83: #333;

$tab-selected-primary: #495057;

$color-alert-danger-1: #f8d7da;
$color-alert-danger-2: #f5c6cb;
$color-alert-danger-3: #721c24;
$color-alert-danger-4: #b10606;

$color-chart-progress-open: $color-13;
$color-chart-progress-not-fixed: #b0008e;
$color-chart-progress-partially-fixed: #002f57;
$color-chart-progress-fixed: #298335;
$color-chart-progress-dismissed: #b10606;
$color-chart-severity-critical: #b10606;
$color-chart-severity-high: #b85000;
$color-chart-severity-low: #0552b8;

$bg-attachment-thumbnail: #c4c4c4;

$color-card-border: #dfdfdf;
$color-card-header-background: #f7f7f7;

// Global Component colors
$eap-blue-interaction: #0852ba;
$eap-blue-hover: #06367a;
$eap-blue-inactive: #83a8dd;
$eap-blue-background: #f3f6fc;
$eap-blue-info: #0098c7;
$eap-blue-text: #063e8d;
$eap-blue-primary: #074eb0;
$eap-blue-hover-background: #f3f6fb;
$eap-green-severity-low: #4b9e24;
$eap-grey-copy: #333;
$eap-grey-page-background: #f5f5f5;
$eap-grey-stroke-inactive: #8c8c8c;
$eap-purple-focus: #a30083;
$eap-red-error: #b10606;
$eap-red-severity-critical: #f4364c;
$eap-white: #fff;
$eap-grey-page-background: #f5f5f5;
$eap-blue-notif-info: #e8f6fa;
$eap-green-notif-success: #eff6eb;
$eap-red-notif-fail: #fde1e4;

// Component colors
$bg-header: $color-theme-2;
$header-color: #e6eaee;

$body-bg: $color-theme-13;

$sidebar-bg: #f5f8fc;
$sidebar-item-menu-color: $color-theme-5;

$action-primary: $color-theme-11;
$action-success: #50bd35;
$action-warning: lighten(#ffa500, 30%);
$action-warning-light: $color-48;
$action-warning-dark: #8a4300;
$action-danger: #d4030b;
$action-default: $color-13;
$action-partial-success: $color-theme-19;
$action-scheduled: $color-6;
$action-error: $color-5;
$action-2: #805fa7;

// Charts
$color-critical: #b10606;
$color-high: #8a4300;
$color-low: #0552b8;
$color-info: #50bd35;

// Notifications
$color-notifications-link: $color-18;
$color-pending-notifications: #d82c0d;
$color-hover-notifications-link: #0036a7;
$color-onclick-notification-link: #153860;
$color-active-notifications-link: #100604;

// Others
$border-radius-default: 0.3rem;
$border-radius-lg: 1.25rem;
$border-active-link-size: 0.25rem;

// Paddings / Margins
$margin-xs: 0.4rem;
$margin-sm: 0.6rem;
$margin: 0.95rem;
$margin-md: 1.4rem;
$margin-lg: 1.9rem;
$margin-xl: 3.4rem;

$padding-xs: 0.25rem;
$padding-sm: 0.5rem;
$padding-md: 0.75rem;
$padding: 0.95rem;
$padding-l: 1.5rem;
$padding-lg: 1.9rem;
$padding-xl: 3.4rem;

// Tables
$table-default-color: #dee2e6;

$table-thead-background: transparent;
$table-margin-bottom: $margin;
$table-background-color: transparent;
$table-border-width: 1px;
$table-border-color: lighten($table-default-color, 10%);
$table-cell-padding: $padding-sm;
$table-headed-color: $table-border-color;
$table-hover-color: lighten($color-theme-4, 35%);
$table-striped-color: lighten($color-theme-1, 3%);

// outline

$outline-color: #a30083;

// FORMS

$field-hover-size: 2px;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;

$sizes-list: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge($sizes-list, $sizes);

// switch field
$color-slider-button-disabled: #dcdcdc;
$color-slider-button-disabled-background: #8c8c8c;
$color-slider-button: #fff;
$color-slider-button-background: #0e6341;

// Others
$cursor-disabled: not-allowed;

// Dashboard
// Sidebar
$sidebar-width: 220px;

$header-height: 4rem;
$header-height-xs: 2rem;

// Graphs
$graph-md-height: 400px;

// z-index (for extending this, keep note that max z-index is 2147483647)
// Also keep this in sync with _variables.ts

// 0-9 wiggle room for elements, not components

// 0X-9X relative pop ups
$z-index-dropdown: 10;
$z-index-popover: 20;
$z-index-tooltip: 30;

// 0XX-9XX anchored elements
$z-index-sticky: 100;
$z-index-fixed: 200;

// 00XXX-99XXX layers, wiggle room for components
$z-index-loading: 1000;
$z-index-topbar: 2000;
$z-index-sidebar: 3000;
$z-index-skip-to-main-content: 4000;
$z-index-scroll-to-top: 5000;

// 0XXXXX-9XXXXX modals
$z-index-modal: 100000;

// 0XXXXXX-9XXXXXX overlays
$z-index-overlay: 1000000;
