.user-avatar {
  @include avatar(100px, 100px);
  background: $color-theme-2;
  margin-bottom: $margin;
  position: relative;

  img {
    @include center();
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100px;
    max-height: 100px;
    position: absolute;
    width: auto;
  }
}
