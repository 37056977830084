.open-icon {
  width: 1rem;

  &.open-icon-primary {
    fill: $color-theme-5;
  }

  &:focus {
    outline: 2px transparent solid;
    box-shadow:
      0 0 0 2px $color-theme-3,
      0 0 0 4px $color-theme-5,
      0 0 4px 8px $color-theme-3;
  }
}
