@use "sass:math";

.status {
  border-radius: $border-radius-default;
  display: inline-block;
  font-style: normal;
  padding: math.div($padding-xs, 2) $padding-md;
}

.status-pending,
.status-closed {
  color: darken($action-default, 20%);
  background-color: lighten($action-default, 50%);
}

.status-active,
.status-yes,
.status-passed,
.status-complete,
.status-completed,
.status-success,
.status-resolved {
  color: $color-theme-2;
  background-color: darken($action-success, 20%);
}

// sass-lint:disable class-name-format
.status-in_progress {
  color: darken($action-2, 70%);
  background-color: lighten($action-2, 40%);
}

.status-processing,
.status-warning,
.status-new {
  color: darken($action-warning, 70%);
  background-color: $action-warning;
}

.status-cancelled,
.status-inactive {
  color: darken($action-danger, 20%);
  background-color: lighten($action-danger, 40%);
}

.status-no,
.status-error {
  color: darken($action-danger, 20%);
  background-color: lighten($action-danger, 40%);
}

// sass-lint:disable class-name-format
.status-completed_with_errors,
.status-open {
  color: darken($action-partial-success, 20%);
  background-color: lighten($action-partial-success, 40%);
}

.status-scheduled,
.status-on_hold {
  color: darken($action-scheduled, 40%);
  background-color: lighten($action-scheduled, 50%);
}

.status-removed {
  color: darken($action-error, 40%);
  background-color: lighten($action-error, 30%);
  text-decoration: line-through;
}

.severity {
  display: flex;
  font-weight: bold;

  svg {
    width: $font-size;
    margin-right: $margin-xs;
  }

  &.severity-info {
    color: $color-info;

    svg {
      fill: $color-info;
    }
  }

  &.severity-high {
    color: $color-high;

    svg {
      fill: $color-high;
    }
  }

  &.severity-low {
    color: $color-low;

    svg {
      fill: $color-low;
    }
  }

  &.severity-critical {
    color: $color-critical;

    svg {
      fill: $color-critical;
    }
  }
}
