.tag {
  border-radius: 3px;
  display: inline-block;
  font-size: $font-size-xs;
  line-height: 1rem;
  padding: 2px 6px;
  white-space: nowrap;
}

.tag-default {
  background-color: #e6e6e6;
  color: $color-theme-5;
}

.tag-success {
  background-color: #41b314;
  color: $color-theme-2;
}

.tag-warning {
  background-color: #e4cb10;
  color: $color-theme-5;
}

.tag-danger {
  background-color: #d9534f;
  color: $color-theme-2;
}
