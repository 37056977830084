.pagination-entries {
  align-items: center;
  display: flex;
  margin: 0 0 1rem;

  &.pagination-right {
    justify-content: flex-end;
  }

  @media #{$small-only} {
    justify-content: space-evenly;
  }

  @media #{$medium-up} {
    justify-content: flex-start;
  }
}
