@import "sass/variables";

.color-error {
  color: darken($action-danger, 10%);
}

.color-success {
  color: darken($action-success, 10%);
}

.color-action {
  color: darken($color-theme-4, 10%);
}

.color-action-dark {
  color: $action-warning-dark;
}

.eap-grey-stroke-inactive {
  color: $eap-grey-stroke-inactive;
}

.eap-red-error {
  color: $eap-red-error;
}

.color-body {
  color: $color-13;
}

.color-inherit {
  color: inherit;
}
