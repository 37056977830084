.alert {
  border-radius: $border-radius-default;
  margin-bottom: $margin;
  position: relative;

  @media #{$medium-up} {
    padding: $padding $padding-lg $padding $padding;
  }

  @media #{$small-only} {
    padding: $padding-sm;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    color: inherit;
    font-weight: 500;
    line-height: $default-line-height;
  }

  ul,
  ol {
    font-size: $font-size-md;
    margin: 0 0 0 1.25rem;
    padding: 0;

    li {
      margin: 0 0 0.5rem;
      padding: 0;
      word-break: break-all;

      &:last-child {
        margin: 0;
      }
    }
  }

  p {
    font-size: $font-size-md;
    white-space: pre-wrap;
    word-break: break-word;

    &:last-of-type {
      margin: 0;
    }
  }
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissable {
  .close-alert {
    background: none;
    border: 0;
    color: inherit;
    line-height: 0;
    opacity: 0.5;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 1.25rem;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }
}

.alert-info {
  color: $color-theme-5;
  background-color: lighten($action-primary, 52%);
  border: 1px solid lighten($action-primary, 52%);

  em {
    border-color: $action-primary;
  }
}

.alert-success {
  color: $color-theme-5;
  background-color: lighten($action-success, 40%);
  border: 1px solid lighten($action-success, 40%);

  em {
    border-color: $action-success;
  }
}

.alert-warning {
  background-color: lighten($action-warning, 10%);
  border: 1px solid lighten($action-warning, 10%);

  em {
    border-color: $action-warning;
  }

  &.compact {
    color: $color-49;
    background-color: $action-warning-light;
    border: 1px solid $action-warning-light;
    padding: 0.75rem 1.25rem;

    em {
      border-color: $action-warning-light;
    }
  }
}

.alert-danger {
  background-color: lighten($action-danger, 40%);
  border: 1px solid lighten($action-danger, 40%);

  em {
    border-color: $action-danger;
  }
}
