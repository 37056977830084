// sass-lint:disable no-important

@media print {
  *,
  ::after,
  ::before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    box-shadow: none !important;
  }

  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline;
    }

    &[href] {
      &::after {
        content: " (" attr(href) ")";
      }
    }

    &[href^="javascript:"],
    &[href^="#"] {
      &::after {
        content: "";
      }
    }
  }

  abbr {
    &[title] {
      &::after {
        content: " (" attr(title) ")";
      }
    }
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .sidebar {
    display: none;
  }
}
