// sass-lint:disable no-vendor-prefixes
@import "@levelaccess/design-system/src/styles/colors";

%button-common {
  align-items: center;
  border-radius: $border-radius-default;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-body;
  font-weight: 400;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition:
    background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;

  &:disabled {
    cursor: $cursor-disabled;
    opacity: 0.6;
  }

  &:active {
    outline: none;

    &::before {
      animation: ripple 1s ease-out;
    }
  }

  .icon {
    width: 16px;
  }

  svg {
    width: 0.9rem;
    height: 0.9rem;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@mixin button($color, $bg-color: null, $border-color: null, $box-shadow: null, $no-focus-hover: null) {
  background: $bg-color;

  // @if (type-of($bg-color) == 'color'){
  //   background: linear-gradient(180deg, $bg-color, darken($bg-color, 10%));
  // }

  border: 1px solid;
  color: $color;
  transition:
    background-color 0.1s ease-in-out,
    border-color 0.1s ease;

  @if $border-color {
    border-color: $border-color;
  } @else {
    border-color: transparent;
  }

  @if $box-shadow {
    box-shadow: $box-shadow;
  }

  @if $no-focus-hover == null {
    &:focus {
      color: $color;
      text-decoration: none;

      @if $bg-color and $bg-color != none and $bg-color != transparent {
        background: darken($bg-color, 10%);
      }
    }

    &:hover {
      text-decoration: none;

      &:not(:disabled) {
        color: $color;

        @if $bg-color and $bg-color != none and $bg-color != transparent {
          background: darken($bg-color, 10%);
        }

        @if $border-color and $border-color != none and $border-color != transparent {
          border-color: darken($border-color, 11.5%);
        }
      }
    }
  }

  &:active {
    color: $color;

    @if $bg-color and $bg-color != none and $bg-color != transparent {
      background: darken($bg-color, 15%);
    }

    @if $border-color and $border-color != none and $border-color != transparent {
      border-color: darken($border-color, 16.5%);
    }
  }

  svg {
    fill: $color;
  }
}

.btn-xs {
  padding: 1px 8px;

  .icon {
    width: 12px;
  }
}

.btn-sm {
  padding: 3px 14px;

  .icon {
    width: 12px;
  }
}

.btn-lg {
  padding: 8px 24px;

  .icon {
    width: 14px;
  }
}

.btn-default {
  @extend %button-common;

  @include button($action-primary, lighten($action-primary, 66%), lighten($action-primary, 66%));
}

.btn-action {
  @extend %button-common;

  @include button($action-primary, transparent, transparent, none, true);

  &:focus,
  &:hover {
    background: lighten($action-primary, 38%);
    color: darken($action-primary, 20%);
  }

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-color: darken($action-primary, 10%);
    outline: none;
  }
}

.btn-action-2 {
  @extend %button-common;

  @include button($color-theme-2, $action-2);
}

.btn-transparent {
  @extend %button-common;

  @include button($color-8);
}

.btn-icon {
  @extend %button-common;

  @include button($color-8, transparent, transparent, none, true);

  &:focus,
  &:hover {
    color: $action-primary;
  }
}

.btn-primary {
  @extend %button-common;

  @include button($color-theme-2, $action-primary, $action-primary);
}

.btn-pagination-default {
  @extend %button-common;

  @include button($ds-color-interactive, transparent, null, none, true);

  &:focus,
  &:hover {
    background: $ds-color-background-primary;
    color: $ds-color-interactive-hover;
    text-decoration: underline;
  }
}

.btn-pagination-selected {
  @extend %button-common;

  @include button($ds-white, $ds-color-interactive);

  &:focus,
  &:hover {
    background-color: $ds-color-interactive-hover;
    text-decoration: underline;
  }
}

.btn-link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: darken($color-29, 25%);
  }
}

.card-header {
  $box-shadow:
    0 0 0 0.15rem $color-38,
    0 0 0 0.3rem $color-29;

  .btn-link {
    background-color: $color-38;
  }
}

th {
  &.sortable {
    $box-shadow:
      0 0 0 0.15rem $color-theme-27,
      0 0 0 0.3rem $color-29;

    .btn-link {
      background-color: $color-theme-27;
    }
  }
}

.btn-outline-primary {
  background-color: $color-theme-2;
}

.btn-warning {
  @extend %button-common;

  @include button($color-theme-5, $action-warning, $action-warning);
}

.btn-success {
  @extend %button-common;

  @include button($color-theme-2);
}

.btn-danger {
  @extend %button-common;

  @include button($color-theme-2, $action-danger, $action-danger);
}

.btn-secondary {
  @extend %button-common;

  @include button($color-theme-2, $color-theme-8, $color-theme-8);
}

.btn-role {
  @extend %button-common;
  @include button($color-theme-5, $color-theme-2, $color-theme-2);

  line-height: inherit;
}

.btn-role-active {
  @extend %button-common;
  @include button($color-theme-2, $color-theme-5, $color-theme-5);
  line-height: inherit;
}

.btn-login {
  @extend %button-common;
  @include button($color-theme-2, $color-76, null, null, true);
}

.no-marker {
  display: flex;

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }
}

.react-action {
  align-items: center;
  background: none;
  border: 0;
  display: inline-block;
  position: relative;

  summary {
    display: flex;
    align-items: center;
    padding: ($padding-xs * 0.5) ($padding + 0.5rem);

    &::-webkit-details-marker {
      display: none;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.more-action {
  align-items: center;
  background: none;
  border: 0;
  display: inline-block;
  position: relative;

  & > summary {
    display: block;
    user-select: none;
    height: 1.35rem;
    padding: $padding-xs $padding-sm;

    &::-webkit-details-marker {
      display: none;
    }

    &::before {
      content: "...";
      font-weight: bold;
      font-size: 2rem;
      line-height: 0; // adjust the line-height to center vertically
      color: $color-13;
      text-align: center;
      display: block;
    }
  }
}

body {
  .dropdown-action-menu {
    animation-duration: 0.15s;
    animation-name: scale-in;
    animation-timing-function: cubic-bezier(0.2, 0, 0.13, 1.5);
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 4px;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
    right: 0;
    list-style: none;
    margin-top: 2px;
    position: absolute;
    top: 100%;
    width: 15rem;
    z-index: $z-index-dropdown;

    &::after {
      left: auto;
      right: 10px;
      top: -14px;
      border: 7px solid transparent;
      border-bottom-color: #fff;
    }

    ul {
      font-size: $font-size;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0;

        button {
          background: none;
          border: 0;
          box-sizing: border-box;
          display: block;
          padding: $padding-xs $padding;
          text-align: left;
          width: 100%;

          &:focus,
          &:hover {
            background-color: $color-15;
            color: $color-theme-2;
          }
        }
      }
    }
  }
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.btn-block {
  display: block;
  width: 100%;
}

:root {
  .show-password-button {
    @include center(false, true);
    background: none;
    box-shadow: none;
    opacity: 0.5;
    position: absolute;
    right: 8px;

    &:focus,
    &:hover {
      color: $color-18;
      opacity: 1;
    }

    &.pressed {
      opacity: 1;
    }
  }
}
