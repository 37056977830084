@use "sass:math";
@import "../variables";

.badge {
  font-size: 100%;
  padding: 0.25em 0.4em;
  border-radius: $border-radius-default;

  &.eap-badge-success {
    color: $color-theme-2;
    background-color: $color-31;
  }

  &.eap-badge-dark {
    color: $color-theme-2;
    background-color: $color-33;
  }
}

.scan-tag {
  border-radius: $border-radius-default;
  display: inline-block;
  font-style: normal;
  padding: math.div($padding-xs, 2) $padding-md;
  color: $color-theme-5;
  background-color: $color-theme-13;
  width: fit-content;
}

.scan-tag-alt-badge {
  @extend .scan-tag; // sass-lint:disable-line placeholder-in-extend
  color: $color-theme-2;
  font-weight: $font-weight-500;
  background-color: $color-47;
}
