// sass-lint:disable no-vendor-prefixes
// Note: external fonts are loaded in index.html

@import "@levelaccess/design-system/src/styles/colors";
@import "sass/variables";

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  image-rendering: pixelated;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.body-copy,
body {
  font-family: $font-family-base;
  font-weight: normal;
  line-height: $default-line-height;
  font-size: $font-size-body;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: $body-bg;
  color: $ds-color-text-dark;
  min-height: 100vh;
  position: relative;

  &::after {
    content: "";
    display: table; // NOTE: Display "block" does not seem to work with height: 0px
    height: 0;
  }
}

pre {
  overflow: initial;
  white-space: pre-wrap;
}

button,
input,
select,
textarea {
  font-family: $font-family-base;
}

dfn,
code {
  @extend %hyphenate;
  background-color: $color-theme-27;
  border: transparent;
  border-radius: $border-radius-default;
  color: darken($color-theme-24, 15%);
  font-family: $font-family-monospace;
  font-weight: normal;
  mix-blend-mode: multiply;
  padding: 2px 3px;
}

p {
  margin: 0 0 1rem;
}

strong {
  font-weight: 700;
}

kbd {
  display: inline-block;
  margin: 0 $margin-xs;
  padding: $padding-xs $padding-sm;
  color: #242729;
  text-shadow: 0 1px 0 $color-theme-2;
  background-color: #e1e3e5;
  border: 1px solid #adb3b9;
  border-radius: 3px;
  box-shadow:
    0 1px 0 rgba(12, 13, 14, 0.2),
    0 0 0 2px $color-theme-2 inset;
  white-space: nowrap;
}

.content-spacer-empty {
  border: 0;
  display: block;
  margin: $margin-lg 0;
  height: 1px;
}

.flex-1 {
  flex: 1;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

@media (max-width: 768px) {
  .full-width-sm {
    width: 100%;
  }
}
