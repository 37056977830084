@use "sass:math";
@import "colors";
@import "card";
@import "general";
@import "main";
@import "links";
@import "forms";
@import "buttons";
@import "alerts";
@import "pagination";
@import "print";
@import "table";
@import "tags";
@import "typography";
@import "float";
@import "status";
@import "upload";
@import "viewport";
@import "file-icon";
@import "icon-standalone";
@import "images";
@import "badge";
@import "spinner";
@import "dropdown";
@import "tooltip";
@import "popover";
@import "modals";
@import "reset";
@import "typeahead";
@import "croppie/croppie.css";
@import "highlight.js/styles/github.css";

$info-box-padding: 1rem 1.5rem;

.divider {
  border-bottom: 1px solid $color-21;
}

.info-box {
  border: solid 2px $color-theme-26;
  border-radius: $border-radius-default;
  display: flex;
  justify-content: space-between;
  padding: $info-box-padding;
  width: fit-content;
  align-items: center;
  flex-direction: column;
  line-height: normal;

  .info-box-title {
    font-size: $font-size-lg;
    font-weight: bold;
  }
}

.info-box-danger {
  border: solid 2px $color-theme-24;
  border-radius: $border-radius-default;
  display: flex;
  justify-content: space-between;
  padding: $info-box-padding;
  width: fit-content;
  align-items: center;
  flex-direction: column;
  line-height: normal;
  color: $color-theme-24;

  .info-box-title {
    font-size: $font-size-lg;
    font-weight: bold;
  }
}

.border-top-black {
  border-top: $border-width solid #555;
}

.border-bottom-black {
  border-bottom: $border-width solid #555;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.cursor-tooltip {
  display: flex;
  align-items: center;
  position: fixed;
  padding: math.div($padding-sm, 2) $padding-sm;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  color: $color-theme-2;
  background: $color-theme-5;

  &::after {
    content: "";
    position: absolute;
    top: 8px;
    left: -8px;
    border: 1px solid $color-theme-5;
    border-color: transparent $color-theme-5;
    border-width: 7px 8px 7px 0;
  }

  > .color-square {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border: 1px solid $color-theme-2;
    background: $color-theme-5;

    &.not-fixed {
      background: $color-chart-progress-not-fixed;
    }

    &.partially-fixed {
      background: $color-chart-progress-partially-fixed;
    }

    &.fixed {
      background: $color-chart-progress-fixed;
    }
  }
}

.search-input-wrap {
  position: relative;
  height: 3rem;

  > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    &:nth-child(1) {
      left: 0.8rem;
      width: 1.2rem;
      height: 1.2rem;
      fill: $color-13;
    }

    &:nth-child(2) {
      left: 13.2rem;
      width: 12px;
      height: 12px;
      fill: $color-29;
      cursor: pointer;
    }
  }

  > input {
    padding-right: 6rem;

    &.form-element {
      &:not([type="file"]) {
        &:not([type="radio"]) {
          &:not([type="checkbox"]) {
            &:not([type="range"]) {
              max-width: 15rem;
              height: 100%;
              padding-left: 2.5rem;
              padding-right: 2.5rem;

              &:hover,
              &:focus {
                & + button {
                  box-shadow: 0 0 0 2px $color-29;
                }
              }
            }
          }
        }
      }
    }
  }

  > button {
    position: relative;
    right: 4px;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: box-shadow 0.1s ease-in-out;
  }
}
