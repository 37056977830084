@import "sass/variables";

.popover {
  z-index: $z-index-sidebar + $z-index-popover;

  &.popover-height- {
    &100 {
      .popover-body {
        max-height: 100px;
        overflow: auto;
      }
    }
  }
}
