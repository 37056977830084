@import "sass/variables";

.tablist {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $color-theme-17;
  margin: 0 0 0 -0.25rem;
  padding: 0;

  li {
    list-style: none;
    margin: 0 0.2rem 0 0;

    &:last-child {
      margin: 0;
    }

    a {
      border-radius: 0;
      border-top-left-radius: $border-radius-default;
      border-top-right-radius: $border-radius-default;
      display: inline-block;
      height: 100%;
      margin: 0;
      padding: 0.25rem 0.75rem;

      &:link,
      &:visited {
        background: transparent;
        color: $color-theme-5;
        text-decoration: none;
      }

      &:focus,
      &:hover {
        background: $color-theme-17;
        color: $color-theme-2;
        text-decoration: none;
      }

      &:active {
        background: $color-8;
        color: $color-theme-2;
        text-decoration: none;
      }
    }

    &.selected-tab,
    &.selected-tab {
      a {
        &:link,
        &:visited {
          background: $color-theme-17;
          color: $color-theme-2;
          text-decoration: none;
        }

        &:focus,
        &:hover {
          color: $color-theme-2;
          text-decoration: none;
        }

        &:active {
          color: $color-theme-2;
          text-decoration: none;
        }
      }
    }
  }
}

.tablist-base {
  display: flex;
  flex-direction: row;

  li {
    flex: 1;
    list-style: none;
    text-align: center;

    a {
      text-decoration: none;
    }

    .items-count {
      border: 1px solid transparent;
    }
  }

  .tab-blue {
    a {
      color: $color-29;

      .items-count {
        color: $color-29;
        border-color: $color-29;
        background-color: $color-theme-2;
      }
    }
  }

  .tab-green {
    a {
      color: $color-31;

      .items-count {
        color: $color-31;
        border-color: $color-31;
        background-color: $color-theme-2;
      }
    }
  }

  .tab-gray {
    a {
      color: $color-13;

      .items-count {
        color: $color-13;
        border-color: $color-13;
        background-color: $color-theme-2;
      }
    }
  }

  .tab-darkblue {
    a {
      color: $color-33;

      .items-count {
        color: $color-33;
        border-color: $color-33;
        background-color: $color-theme-2;
      }
    }
  }

  .items-count {
    font-weight: 700;
    color: $color-theme-2;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }
}

@mixin tablist-desktop {
  li {
    border-bottom: 8px solid $color-32;
    padding-bottom: $padding-md;

    a {
      font-weight: 700;
      display: flex;
      justify-content: center;

      .items-count {
        margin-left: $margin-sm;
      }
    }
  }
}

@mixin tablist-mobile {
  li {
    border: 1px solid $color-32;
    border-radius: 5px;
    margin: $margin-xs;
    padding: $padding-md 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      font-weight: 400;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@mixin highlight-selected-tab {
  .selected-tab {
    &.tab-blue {
      border-color: $color-29;

      .items-count {
        color: $color-theme-2;
        background-color: $color-29;
      }
    }

    &.tab-green {
      border-color: $color-31;

      .items-count {
        color: $color-theme-2;
        background-color: $color-31;
      }
    }

    &.tab-gray {
      border-color: $color-13;

      .items-count {
        color: $color-theme-2;
        background-color: $color-13;
      }
    }

    &.tab-darkblue {
      border-color: $color-33;

      .items-count {
        color: $color-theme-2;
        background-color: $color-33;
      }
    }
  }
}
