// Put styles for small global layouts here

@media #{$xsmall-only} {
  #mainContainer {
    .main-content {
      padding: 0 $padding-xs;
    }

    .card-body,
    .card-header,
    .card-footer {
      padding-left: $padding-sm;
      padding-right: $padding-sm;
    }

    // Fixes horizontal scroll
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    // Fixes horizontal scroll
    [class^="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media #{$small-only}, #{$medium-only} {
  #mainContainer {
    .main-content {
      padding: 0 $padding-md;
    }

    .card-body,
    .card-header,
    .card-footer {
      padding-left: $padding-md;
      padding-right: $padding-md;
    }

    // Fixes horizontal scroll
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    // Fixes horizontal scroll
    [class^="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
