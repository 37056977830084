// sass-lint:disable indentation

$assets-font-path: "~assets/fonts/";

@mixin font-face($font-family, $font-style, $font-weight, $font-path, $font-file, $font-local-1, $font-local-2) {
  @font-face {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src:
      local("#{$font-local-1}"),
      local("#{$font-local-2}"),
      url("#{$font-path}#{$font-file}.woff2") format("woff2"),
      url("#{$font-path}#{$font-file}.woff") format("woff");
  }
}

@include font-face("Roboto", normal, 300, $assets-font-path, "roboto-v20-latin-300", "Roboto Light", "Roboto-Light");
@include font-face("Roboto", normal, 400, $assets-font-path, "roboto-v20-latin-regular", "Roboto", "Roboto-Regular");
@include font-face("Roboto", normal, 500, $assets-font-path, "roboto-v20-latin-500", "Roboto Medium", "Roboto-Medium");
@include font-face("Roboto", normal, 700, $assets-font-path, "roboto-v20-latin-700", "Roboto Bold", "Roboto-Bold");
@include font-face(
  "Roboto Mono",
  normal,
  700,
  $assets-font-path,
  "roboto-mono-v7-latin-regular",
  "Roboto Mono",
  "RobotoMono-Regular"
);
@font-face {
  font-family: "Rubik";
  src:
    local("Rubik"),
    local("Rubik-Regular"),
    url("#{$assets-font-path}Rubik-VariableFont_wght.woff2") format("woff2");
}

.h2,
.h3,
.h4,
.h5,
.h6,
h2,
h3,
h4,
h5,
h6 {
  color: $color-theme-5;
  font-family: $font-family-base;
}

.h1,
h1 {
  font-family: $h1-font-family;
  color: $color-theme-5;
  font-size: $h1-font-size;
  font-weight: $font-weight-600;
  line-height: 2.25rem;
}

.h2,
h2 {
  font-size: $h2-font-size;
  font-weight: $font-weight-600;
  line-height: 2rem;
}

.h3,
h3 {
  font-size: $h3-font-size;
  font-weight: $font-weight-600;
  line-height: 1.75rem;
}

.h4,
h4 {
  font-size: $h4-font-size;
  font-weight: $font-weight-600;
  line-height: 1.625rem;
}

.h5,
h5 {
  font-size: $h5-font-size;
  font-weight: $font-weight-600;
  line-height: 1.5rem;
}

.h6,
h6 {
  font-size: $h6-font-size;
  font-weight: $font-weight-500;
  line-height: 1.5rem;
}

@mixin body-copy-highlight {
  font-family: $font-family-base;
  font-weight: $font-weight-500;
  line-height: $default-line-height;
  font-size: $font-size-body;
}

.body-copy-highlight {
  @include body-copy-highlight;
}

@mixin body-copy-semi-bold {
  font-family: $font-family-base;
  font-weight: $font-weight-600;
  line-height: $default-line-height;
  font-size: $font-size-body;
}

.body-copy-semi-bold {
  @include body-copy-semi-bold;
}

@mixin small-copy {
  font-family: $font-family-base;
  font-weight: $font-weight-400;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.small-copy,
small {
  @include small-copy;
}

@media #{$mobile-range} {
  .h1,
  h1 {
    font-size: $mobile-h1-font-size;
    line-height: 2rem;
  }

  .h2,
  h2 {
    font-size: $mobile-h2-font-size;
    line-height: 1.875rem;
  }

  .h3,
  h3 {
    font-size: $mobile-h3-font-size;
  }

  .h4,
  h4 {
    font-size: $mobile-h4-font-size;
  }

  .h5,
  h5 {
    font-size: $mobile-h5-font-size;
  }

  .h6,
  h6 {
    font-size: $mobile-h6-font-size;
  }
}

.font-normal {
  font-weight: normal;
}

.font-thin {
  font-weight: 300;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.margin-top {
  margin-top: $margin;
}

.margin-bottom {
  margin-bottom: $margin;
}

.margin-left {
  margin-left: $margin;
}

.margin-right {
  margin-right: $margin;
}

.margin-top-xs {
  margin-top: $margin-xs;
}

.margin-bottom-xs {
  margin-bottom: $margin-xs;
}

.margin-left-xs {
  margin-left: $margin-xs;
}

.margin-right-xs {
  margin-right: $margin-xs;
}

.margin-right-sm {
  margin-right: $margin-sm;
}

.margin-top-md {
  margin-top: $margin-md;
}

.margin-bottom-md {
  margin-bottom: $margin-md;
}

.margin-left-md {
  margin-left: $margin-md;
}

.margin-right-md {
  margin-right: $margin-md;
}

.margin-lg {
  margin: $margin-lg;
}

.margin-top-lg {
  margin-top: $margin-lg;
}

.margin-bottom-lg {
  margin-bottom: $margin-lg;
}

.margin-left-lg {
  margin-left: $margin-lg;
}

.margin-right-lg {
  margin-right: $margin-lg;
}

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.padding-top {
  padding-top: $padding;
}

.padding-bottom {
  padding-bottom: $padding;
}

.padding-left {
  padding-left: $padding;
}

.padding-right {
  padding-bottom: $padding;
}

.padding-top-sm {
  padding-top: $padding-sm;
}

.padding-top-md {
  padding-top: $padding-l;
}

.padding-top-lg {
  padding-top: $padding-lg;
}

.padding-bottom-md {
  padding-bottom: $padding-l;
}

.padding-bottom-lg {
  padding-bottom: $padding-lg;
}

.padding-right-sm {
  padding-right: $padding-sm;
}

.padding-right-md {
  padding-right: $padding-md;
}

.padding-right-lg {
  padding-right: $padding-lg;
}

.padding-left-lg {
  padding-left: $padding-lg;
}

.margin-xl {
  margin: $margin-xl;
}

.margin-bottom-sm {
  margin-bottom: $margin-sm;
}

.margin-bottom-xl {
  margin-bottom: $margin-xl;
}

.padding-left-sm {
  padding-left: $padding-sm;
}

.padding-left-md {
  padding-left: $padding-md;
}

.padding-left-xl {
  padding-left: $padding-xl;
}

.padding-bottom-xl {
  padding-bottom: $padding-xl;
}

.padding-bottom-sm {
  padding-bottom: $padding-sm;
}

.padding-right-xl {
  padding-right: $padding-xl;
}

.margin-left-sm {
  margin-left: $margin-sm;
}

.center-vertically {
  display: flex;
  align-items: center;
}

.text-underline {
  text-decoration: underline;
}

.text-black {
  color: $color-theme-5;
}

.text-link {
  color: #0665d0;
}

.font-size-normal {
  font-size: $font-size;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-s {
  font-size: $font-size-sm;
}

.font-size-xs {
  font-size: $font-size-xs;
}

.font-size-xl {
  font-size: $font-size-xl;
}

.pre-line {
  white-space: pre-line;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
