@import "@levelaccess/design-system/src/styles/borders";
@import "@levelaccess/design-system/src/styles/colors";
@import "@levelaccess/design-system/src/styles/spacing";

.card {
  background-color: $color-theme-2;
  background-clip: border-box;
  border-radius: $border-radius-default;
  border: 1px solid $color-card-border;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 0;
  position: relative;
  word-wrap: break-word;

  .card-header {
    padding: 0.75rem 1.25rem;
    background: rgba(0, 0, 0, 0.05);
    border: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }

    h1 {
      &:not(#modalHeader) {
        position: relative;

        span {
          background: $color-theme-2;
          border-radius: $border-radius-default;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          font-size: small;
          left: 0;
          padding: 0.25rem 0.5rem;
          position: absolute;
          top: -2rem;
        }
      }
    }
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  &.new-nav-card {
    &:not(#modalContainer) {
      border: $ds-border-0;
      padding-left: $ds-spacing-00;

      .card-header {
        background-color: $ds-white;
        padding: calc($ds-spacing-20 + $ds-spacing-10) $ds-spacing-00;
      }

      .card-body {
        padding: calc($ds-spacing-30 + $ds-spacing-10) $ds-spacing-00;
      }

      // Nested cards
      .card {
        padding: calc($ds-spacing-20 + $ds-spacing-10) calc($ds-spacing-30 + $ds-spacing-10);
      }
    }
  }
}
