@mixin define-identifier($name) {
  &::before {
    content: $name;
    display: none;
  }
}

:root {
  &body {
    @media #{$small-only} {
      @include define-identifier("small");
    }

    @media #{$medium-only} {
      @include define-identifier("medium");
    }

    @media #{$large-up} {
      @include define-identifier("large");
    }

    @media #{$xlarge-up} {
      @include define-identifier("xlarge");
    }

    @media #{$xxlarge-up} {
      @include define-identifier("xxlarge");
    }
  }
}
