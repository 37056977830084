@import "variables";

$font-size-base: 1rem;
$nav-tabs-link-active-border-color: $color-13 $color-13 $color-theme-2 !default;
$popover-body-padding-y: 0.5rem;
$popover-body-padding-x: 0.75rem;
$input-bg: #fff;

// Hardcoded hex values from Bootstrap 5
$theme-colors: (
  primary: $action-primary,
  secondary: #6c757d,
  success: #198754,
  info: #0dcaf0,
  warning: #ffc107,
  danger: $action-danger,
  light: #f8f9fa,
  dark: $action-default
);

.progress {
  border-radius: 0;
  border: solid 1px $color-83;
}
