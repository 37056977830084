.file-icon {
  background: #018fef;
  border-radius: 2px;
  clip-path: circle(100% at 0% 75%);
  display: inline-block;
  font-style: normal;
  height: 2rem;
  position: relative;
  width: 1.75rem;
  text-align: left;

  i {
    bottom: 2px;
    color: $color-theme-2;
    display: block;
    font-size: 10px;
    font-style: normal;
    left: 0;
    line-height: 10px;
    overflow: hidden;
    padding: 2px;
    position: absolute;
    text-align: center;
    text-transform: lowercase;
    white-space: nowrap;
    width: 100%;
  }
}

.file-icon-zip,
.file-icon-rar,
.file-icon-other {
  background: #acacac;
}

.file-icon-doc,
.file-icon-docx {
  background: #0665d0;
}

.file-icon-xls,
.file-icon-xlsx {
  background: $ds-color-text-dark;
}

.file-icon-ppt,
.file-icon-pptx {
  background: #d24726;
}

.file-icon-pdf {
  background: #b10606;
}

.file-icon-txt {
  background: #5eb533;
}

.file-icon-mp3,
.file-icon-wma,
.file-icon-m4a,
.file-icon-flac {
  background: #8e44ad;
}

.file-icon-mp4,
.file-icon-wmv,
.file-icon-mov,
.file-icon-avi,
.file-icon-mkv {
  background: #7a3ce7;
}

.file-icon-bmp,
.file-icon-jpg,
.file-icon-jpeg,
.file-icon-gif,
.file-icon-png {
  background: #f4b400;
}
