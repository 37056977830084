.spinner {
  display: inline-block;

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }

  & > span {
    animation: bouncedelay 1.4s infinite ease-in-out;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    height: 0.5em;
    margin-right: 0.1em;
    width: 0.5em;
  }

  &.hide {
    opacity: 0;
    animation-play-state: paused;
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    background-color: $color-theme-5;
  }

  40% {
    transform: scale(1);
    background-color: $color-theme-2;
  }
}
