.upload-button {
  min-height: 50px;
  position: relative;

  button {
    background-color: $color-theme-2;
    border: 1px solid darken($color-theme-1, 6%);
    border-radius: $border-radius-default;
    height: 100%;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition:
      border-color 0.1s ease-in-out,
      box-shadow 0.1s ease-in-out;
    width: 100%;
    z-index: 2;

    &:focus {
      &:not(:disabled) {
        background-color: $color-theme-2;
        border-color: transparent;
        box-shadow: 0 0 0 2px $color-18;
        outline: none;
      }
    }

    &:hover {
      &:not(:disabled) {
        border-color: transparent;
        box-shadow: 0 0 0 2px $color-15;
        outline: none;
      }
    }

    &:disabled,
    &[readonly] {
      background-color: $color-theme-1;
      opacity: 1;
    }
  }

  &.on-drag-enter {
    button {
      color: #3e515b;
      background-color: #fff;
      border-color: $color-18;
      outline: none;
      box-shadow: 0 0 0 0.1rem $color-18;
    }
  }

  .input-type-file {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-default;
    display: block;
    height: 100%;
    left: 15px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: calc(100% - 30px); // $margin * 2
    z-index: 1;

    input {
      &[type="file"] {
        opacity: 0;
        font-size: 3rem;
        height: 3rem;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.selected-files-data {
  font-size: $font-size-sm;

  svg {
    width: 1rem;
  }

  thead {
    tr {
      th {
        background: darken($color-theme-13, 80%);
        color: $color-theme-2;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid darken($color-theme-13, 20%);

      &:last-child {
        border-bottom: 0;
      }

      td {
        background: $color-theme-2;

        &:nth-child(1) {
          word-break: break-all;
        }

        &:nth-child(2) {
          text-align: center;
          white-space: nowrap;
        }

        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}

.files-table {
  font-size: $font-size-sm;

  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        &:nth-child(1) {
          word-break: break-all;
        }

        &:nth-child(4) {
          white-space: nowrap;
          vertical-align: middle;
        }

        &:nth-child(5) {
          button {
            padding: $padding-xs;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: center;
        }

        &:nth-child(2),
        &:nth-child(3) {
          white-space: nowrap;
        }
      }
    }
  }
}
