// sass-lint:disable quotes, no-vendor-prefixes

$disabled-opacity: 0.65;
$error-color: #ff2c00;
$error-dirty-background: #fdd;
$input-placeholder-color: #6f6f6f;

label,
.label {
  color: $color-theme-5;
  display: inline-block;
  font-weight: 500;
  margin: 0;
  max-width: 100%;
}

input {
  min-height: 2.35rem;

  &:disabled {
    & + label {
      opacity: $disabled-opacity;
    }
  }

  &[type="checkbox"],
  &[type="radio"] {
    min-height: auto;
  }
}

select {
  background-color: $color-theme-2;
  border: 1px solid $color-62;
  border-radius: $border-radius-default;
  font-size: $font-size;
  max-width: 100%;
  padding: 0 $padding-sm;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  vertical-align: middle;

  &:not([multiple]) {
    height: 2.35rem;
  }

  &.form-select {
    border: 1px solid $color-62;
  }

  &:focus {
    background-color: $color-theme-2;
    box-shadow: 0 0 0 $field-hover-size $color-18;
    outline: none;
  }

  &:disabled {
    background-color: $color-theme-1;
    opacity: $disabled-opacity;
  }

  &::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 0.3em;
    right: 0.75em;
    border-top: 8px solid $color-theme-5;
    opacity: 0.5;
  }

  &::-ms-expand {
    display: none;
  }

  &.form-element {
    &:focus {
      &::-ms-value {
        color: #3e515b;
        background-color: #fff;
      }
    }
  }

  option {
    border-radius: $border-radius-default;
    padding: 0.5rem;
  }

  &[multiple] {
    background: transparent;
    border: 0;
    padding: 2px;

    &::-webkit-scrollbar {
      display: none;
    }

    option {
      margin-bottom: $margin-xs;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        box-shadow: 0 0 0 $field-hover-size $color-15;
      }
    }
  }
}

.form-element {
  // prettier-ignore
  &:not([type="file"]):not([type="radio"]):not([type="checkbox"]):not([type="range"]) {  // sass-lint:disable-line force-pseudo-nesting
    background-color: $color-theme-2;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid $color-62;
    border-radius: $border-radius-default;
    padding: $default-form-element-padding-top-bottom $default-form-element-padding-left-right;
    transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    &[type="datetime-local"],
    &[type="date"] {
      padding: ($default-form-element-padding-top-bottom * 0.65) $default-form-element-padding-left-right;
    }

    &:focus {
      background-color: $color-theme-2;
      border: 1px solid $color-62;
      box-shadow: 0 0 0 $field-hover-size $color-18;
      outline: none;
    }

    &:hover {
      &:not(:disabled) {
        &:not(:read-only) {
          box-shadow: 0 0 0 $field-hover-size $color-15;
          outline: none;
        }
      }
    }

    &[type="file"] {
      line-height: 1rem;
    }

    // prettier-ignore
    &:disabled,
    &:not(select):not(.dropdown-toggle):read-only { // sass-lint:disable-line force-pseudo-nesting
      background-color: $color-theme-1;
      opacity: $disabled-opacity;
    }

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &::-webkit-input-placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }

    &::-ms-clear {
      color: $color-theme-6;
    }

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }
}

.form-element-file,
.form-element-range {
  display: block;
}

// Imported Bootstrap 4 style
// https://github.com/twbs/bootstrap/blob/043a03c95a2ad6738f85b65e53b9dbdfb03b8d10/scss/_forms.scss#L199
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: $margin;
  position: relative;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

details,
summary {
  border-radius: $border-radius-default;

  &:focus {
    box-shadow: 0 0 0 $field-hover-size $color-18;
    outline: none;
  }
}

// FORM VALIDATION

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

form {
  input,
  textarea,
  select {
    &.ng-invalid {
      &.ng-dirty {
        &:not(select) {
          background: $error-dirty-background;
        }
      }

      &.ng-touched {
        &:not(.ng-pristine) {
          border-color: $error-color;
          box-shadow: 0 0 0 0.1rem $error-color;
        }
      }
    }
  }

  app-datetime-local {
    &[aria-invalid="true"] {
      input {
        background: $error-dirty-background;
        border-color: $error-color;
        box-shadow: 0 0 0 0.1rem $error-color;
      }
    }
  }

  .drag-area {
    &.error-highlight {
      background: $error-dirty-background;
      border-color: $error-color;
      box-shadow: 0 0 0 0.1rem $error-color;
      border-radius: $border-radius-default;
    }
  }

  fieldset {
    &.fieldset-form {
      border: 1px solid darken($color-theme-1, 10%);
      border-radius: $border-radius-default;
      padding: $padding;

      legend {
        background: darken($color-theme-1, 10%);
        border-radius: $border-radius-default;
        color: $color-8;
        font-size: $font-size;
        font-weight: 500;
        margin: 0;
        padding: $padding-xs $padding-sm;
        width: auto;
      }
    }
  }
}

.form-control-validation-error {
  color: darken($error-dirty-background, 30%);
}

// PLACEHOLDER

.placeholder-shown {
  position: relative;

  & + span {
    color: $color-13;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 0.2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &:focus {
    &:not(:placeholder-shown),
    &:not(-ms-input-placeholder) {
      & + span {
        opacity: 1;
        transform: translateY(-100%);
      }
    }
  }
}

.placeholder-visible {
  & + small {
    background: $color-theme-4;
    border: 0;
    border-radius: $border-radius-default;
    color: $color-theme-2;
    display: none;
    left: $margin;
    padding: 0 $padding-sm;
    position: absolute;
    top: -2.1rem;
    z-index: 2;

    @media #{$small-only} {
      right: 1rem;
    }
  }

  &:focus + small {
    display: inline-block;
  }
}

// Note: :-ms-input-placeholder IE10/11 only, without Edge
textarea,
input {
  &:-ms-input-placeholder,
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.element-state {
  border-radius: $border-radius-default;
  display: inline-block;
  font-size: 0.7em;
  font-weight: normal;
  line-height: 1em;
  padding: 3px 6px;
  position: absolute;
  z-index: 2;

  &.element-state-disabled {
    background: $color-theme-2;
    color: lighten($color-theme-5, 20%);
  }

  &.element-state-readonly {
    background: $color-theme-2;
    color: lighten($color-theme-5, 20%);
  }
}

.form-error-row {
  margin-top: -$margin;
  margin-bottom: 0.95rem;
}

.textarea-resize-none {
  resize: none;
}

.input-description {
  padding: $padding-xs 0;
  font-size: $font-size-md;
}
