@use "sass:math";

.pagination-details {
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    li {
      align-self: center;
      display: inline-block;
      list-style: none;
      margin: 0 math.div($margin-xs, 2);
    }
  }
}
