// sass-lint:disable no-important

// .example {
//      @extend %rule;
// }

%visuallyhidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: none; // Expected 'none' or URL but found 'polygon('.  Error in parsing value for 'clip-path'.  Declaration dropped. (from Firefox console)
  top: -1px; // Prevents from causing scroll bar when body uses min-height: 100vh;
}

@mixin visuallyhidden() {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: none; // Expected 'none' or URL but found 'polygon('.  Error in parsing value for 'clip-path'.  Declaration dropped. (from Firefox console)
  top: -1px; // Prevents from causing scroll bar when body uses min-height: 100vh;
}
