@use "sass:math";

// sass-lint:disable force-pseudo-nesting
a {
  color: $color-29;
  background-color: transparent;
  border-radius: $border-radius-default;
  position: relative;
  text-decoration: none;
  text-decoration-skip: objects;

  &[target="_blank"] {
    &[rel="noreferrer noopener"] {
      > i {
        &[aria-hidden="true"] {
          display: none;
          background-color: darken($color-29, 25%);
          border-radius: 3px;
          color: $color-theme-2;
          font-size: small;
          line-height: 1rem;
          margin-left: math.div($margin-xs, 2);
          padding: 0.05rem 0.15rem;
          position: absolute;
          right: -1.3rem;
          z-index: $z-index-tooltip;
        }
      }
    }
  }

  &:focus,
  &:hover {
    color: darken($color-29, 25%);
    text-decoration: underline;

    &.ds-btn {
      color: $ds-white;
      text-decoration: unset;
    }

    &[target="_blank"] {
      &[rel="noreferrer noopener"] {
        > i {
          &[aria-hidden="true"] {
            display: inline-block;

            &::after {
              content: "\2197";
            }
          }
        }
      }
    }
  }

  &:active {
    color: darken($color-theme-17, 25%);
  }

  &.page-link {
    &:focus {
      box-shadow:
        0 0 0 0.15rem #fff,
        0 0 0 0.3rem $color-29;
    }
  }
}

.link-underline {
  font-weight: 700;
  text-decoration-line: underline;
}
