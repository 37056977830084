#app-container {
  .angular-editor-textarea {
    background-color: $color-theme-2;
    border-radius: $border-radius-default;

    &:focus {
      background-color: $color-theme-2;
      border-color: lighten($color-theme-1, 3%);
      box-shadow: 0 0 0 $field-hover-size $color-18;
      outline: none;
    }

    &:hover {
      border-color: lighten($color-theme-1, 6%);
      box-shadow: 0 0 0 $field-hover-size $color-15;
      outline: none;
    }
  }

  .angular-editor-toolbar {
    border-radius: $border-radius-default;

    .angular-editor-toolbar-set {
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        z-index: -1;
        position: fixed;
        visibility: hidden;
      }
    }
  }
}
