@import "sass/variables";
@import "sass/helpers/helpers";

.hide {
  display: none;
}

.visuallyhidden {
  @extend %visuallyhidden;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-center {
  vertical-align: middle;
}

.sidebar-hidden-mobile {
  display: none;

  @media #{$large-up} {
    display: block;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
