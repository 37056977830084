$top-border-width: 5px;

.top-n {
  height: 100%;

  .issue-counter {
    font-size: $h3-font-size;
    font-weight: 600;
  }

  > div {
    height: 100%;
    border: 1px solid $color-theme-1;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

    > .heading {
      h3 {
        border-bottom: 1px solid $color-theme-1;
        font-size: 1.2rem;

        svg {
          position: relative;
          top: -0.1ch;
          width: 2ch;
          margin-right: 0.5ch;
        }
      }
    }

    > .content {
      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          ol {
            margin: 0;
            padding: 0;

            li {
              list-style: none;
            }
          }
        }
      }
    }

    &.critical {
      .color {
        color: $color-critical;
      }

      > .heading {
        border-top: $top-border-width solid $color-critical;

        svg {
          fill: $color-critical;
        }
      }
    }

    &.high {
      .color {
        color: $color-high;
      }

      > .heading {
        border-top: $top-border-width solid $color-high;

        svg {
          fill: $color-high;
        }
      }
    }

    &.low {
      .color {
        color: $color-low;
      }

      > .heading {
        border-top: $top-border-width solid $color-low;

        svg {
          fill: $color-low;
        }
      }
    }
  }
}
