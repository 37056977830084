// Classes that fixes unability of a screen reader to read buttons inside role="group" parent.

.btn-group {
  &.btn-group-common {
    > .btn {
      border-radius: 0.25rem;

      &:not(:last-child) {
        border-radius: 0.25rem;
      }
    }
  }
}
