.dropdown {
  .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;

    .dropdown-item {
      &:focus,
      &:hover {
        background-color: $color-15;
        color: $color-theme-2;
      }
    }
  }
}
