table {
  background-color: $table-background-color;
  max-width: 100%;
  width: 100%;

  caption {
    color: $color-theme-5;
    padding: $padding 0;
    caption-side: top;
  }

  th {
    background: $table-thead-background;
    color: $color-theme-5;
    font-weight: bold;
    padding: $table-cell-padding;
    text-align: center;
    vertical-align: top;
  }

  td {
    font-size: 90%;
    padding: $table-cell-padding;
    text-align: left;
  }
}

.table-td-vertical-align-top {
  tbody {
    tr {
      td {
        vertical-align: top;
      }
    }
  }
}

.table-tbody-tr-border {
  tbody {
    tr {
      td {
        border-top: $table-border-width solid $table-border-color;
      }
    }
  }
}

.table-borders {
  border: $table-border-width solid $table-border-color;
}

.table-headed {
  th {
    background-color: $table-default-color;
    border-right: $table-border-width solid $table-border-color;

    &:last-child {
      border-right: 0;
    }
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $color-theme-2;
      }

      &:nth-child(even) {
        background-color: $table-striped-color;
      }
    }
  }
}

.row-details {
  padding: $padding-sm 0;

  &:nth-child(even) {
    background-color: $table-striped-color;
  }
}

#app-container {
  .table-tr-hover {
    tbody {
      tr {
        &:hover {
          background-color: $table-hover-color;
        }
      }
    }
  }
}

.breakdown-legend-table {
  // sass-lint:disable-block no-important
  table {
    border: 0 !important;

    > thead {
      background: none !important;
    }

    tr {
      border: 0 !important;
    }

    th {
      padding: 0.75rem 0.5rem !important;
      border-top: 0 !important;
    }

    td {
      padding: 0.65rem 0.5rem !important;
      border-bottom: 1px solid $table-default-color !important;
    }
  }
}
