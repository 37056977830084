.busy-indicator-overlay {
  opacity: 0.3;

  @supports (filter: blur()) {
    div {
      opacity: 1;
      filter: blur(2px);
    }
  }
}
